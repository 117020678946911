<template>
  <div id="event_info" class="event_public">
    <EventHeader :eventHeaderItems="eventHeaderItems" :key="componentKey" />

    <PageSubHeader :pageSubHeader="pageSubHeader" :urlQuery="urlQuery" class="annual" />

    <div class="information">
      <div class="title w1100 mg-b-50">
        <h3 class="txt-bold">{{ pageTitle }}</h3>
        <img src="@/statics/img/index/icon_title2.svg" alt="">
      </div>
      <div class="w1000">
        <!-- form area -->
        <div class="form_box">
          <div id="form_annual_memberTrue_signup" class="form">

            <!-- 表單互動區 -->
            <div class="form_interactive">
              <template v-if="isAuthenticated">
                <div class="half">
                  <div class="fixed_info w50">
                    <p class="fixed_info_title">姓名</p>
                    <p class="fixed_info_content">{{ user.realname }}</p>
                  </div>
                  <!-- <div class="fixed_info w50">
                    <p class="fixed_info_title">會員編號</p>
                    <p class="fixed_info_content">{{ user_no }}</p>
                  </div> -->
                  <Radio
                    class="w50"
                    v-model="user.level"
                    :item="inputFormat.levelRadio"
                    :readonly="true"
                  />
                </div>
                <div class="half">
                  <!-- <div class="fixed_info w100">
                    <p class="fixed_info_title">出生年月日</p>
                    <p class="fixed_info_content">{{ user.birthday }}</p>
                  </div> -->
                  <div class="fixed_info w100">
                    <p class="fixed_info_title">身分證字號</p>
                    <p class="fixed_info_content">{{ user.id_number }}</p>
                  </div>
                </div>
                <!-- <div class="fixed_info w50">
                  <p class="fixed_info_title">身分證字號</p>
                  <p class="fixed_info_content">{{ user.id_number }}</p>
                </div> -->
              </template>
              <template v-else>
                <TextInput
                  class="w50"
                  v-model.trim="user.realname"
                  :item="inputFormat.nameInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    val => val.length > 1 || '長度錯誤!',
                  ]"
                />
                <div class="half">
                  <label for="birthday" class="w100">
                    <p><span class="txt-red txt-regular">*</span>出生年月日</p>
                    <date-pick class="w100" v-model="user.birthday" :format="'YYYY-MM-DD'">
                    </date-pick>
                  </label>
                </div>
                <TextInput
                  class="w50"
                  v-model.trim="user.id_number"
                  :item="inputFormat.idInput"
                  :rules="[
                    val => !!val || '必填欄位！',
                    [/^[A-Z][1-2]\d{8}$/, '身分證格式錯誤']
                  ]"
                  :maxlength="10"
                />
              </template>

              <TextInput
                class="w50"
                v-model.trim="user.phone"
                :item="inputFormat.phoneInput"
                :rules="[
                  val => !!val || '必填欄位！',
                  [/^09\d{8}$/, '手機格式錯誤']
                ]"
                :maxlength="10"
              />

              <TextInput
                class="w50"
                v-model.trim="user.hospital_name"
                :item="inputFormat.hospitalIdInput"
                :rules="[
                  val => !!val || '必填欄位！',
                  val => val.length > 1 || '長度錯誤!',
                ]"
              />

              <div class="select_container w100">
                <p><span class="txt-red txt-regular">*</span>院所地址</p>
                <div class="select_container-left err_message_box w50">
                  <span class="err_message txt-red small">{{ hospitalAddressErrorMsg }}</span>
                  <select
                    v-model="user.city"
                    class="select_city address_top w33"
                    @change="resetHospitalSelect"
                  >
                    <option value="" selected>請選擇</option>
                    <option
                      v-for="(town, index) in hospitalTowns"
                      :key="index"
                      :value="town"
                    >{{ town }}
                    </option>
                  </select>
                  <select
                    v-model="user.area"
                    class="select_city address_top w33"
                    @change="updateHospitalZip"
                  >
                    <option value="" selected>請選擇</option>
                    <option
                      v-for="(area, index) in hospitalAreas"
                      :key="index"
                      :value="area"
                    >{{ area }}
                    </option>
                  </select>
                  <input
                    :value="user.zipcode"
                    type="text"
                    readonly
                    class="address_top w33"
                    placeholder="郵遞區號"
                  >
                </div>
                <div class="select_container-right w50">
                  <TextInput
                    class="address_top"
                    v-model.trim="user.addr"
                    :item="inputFormat.addressHospitalDetailInput"
                    :rules="[
                      val => !!val || '必填欄位！',
                    ]"
                  />
                </div>
              </div>

              <TextInput
                class="w50"
                v-model.trim="user.email"
                :item="inputFormat.emailInput"
                :rules="[
                  val => !!val || '必填欄位！',
                  [/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/,
                  '信箱格式錯誤'],
                ]"
              />

              <TextInput
                class="w50"
                v-model.trim="user.receipt_title"
                :item="inputFormat.feeTitleInput"
              />

              <div
                class="fraction_radio w100"
                v-if="user.level * 1 === 1
                && (eventInfo.fraction_a * 1 !== 0 || eventInfo.fraction_b * 1 !== 0)"
              >
                <RadioFraction
                  :chosen="chosenRadioFilter(user.fractions)"
                  :item="fractionRadio(eventInfo)"
                  @updateFraction="updateFraction"
                  :required="true"
                />
              </div>

              <div class="fraction_checkbox w100">
                <template v-if="isFractionExist(eventInfo)">
                  <CheckboxFraction
                    :chosen="chosenCheckFilter(user.fractions)"
                    :item="fractionCheckbox(eventInfo)"
                    @updateFraction="updateFraction"
                    :required="false"
                  />
                </template>
                <template v-else>
                  <p class="txt-light_green">無其他可選擇積分</p>
                </template>
              </div>

              <Radio
                v-if="parseInt(eventInfo.food_type, 10) === 1"
                class="w50"
                v-model="user.food_type"
                :item="eatTypeRadio"
              />

            </div>

            <div class="form_submit txt-center">
              <button class="btn p" @click="onSubmit">下一步</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cities from '@/data/cities.json';

import TextInput from '@/components/form/TextInput.vue';
import Radio from '@/components/form/Radio.vue';
import RadioFraction from '@/components/form/RadioFraction.vue';
import CheckboxFraction from '@/components/form/CheckboxFraction.vue';

import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';

import EventHeader from '@/components/EventHeader.vue';
import PageSubHeader from '@/components/PageSubHeader.vue';
import { RegisterEventSubHeader } from '@/lib/const';
import { eventsView, userInfo } from '@/lib/http';
import { todayValid, quotaValid, levelValid } from '@/lib/public';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'RegisterEventMember',
  data() {
    return {
      /**
       * Static Data
       */
      ...RegisterEventSubHeader,
      eventID: '',
      componentKey: 0,
      hospitalAddressErrorMsg: '',
      user_no: '',
      user: {
        event_uuid: '',
        realname: '',
        // birthday: '1970-01-01',
        level: '',
        id_number: '',
        phone: '',
        hospital_name: '',
        city: '',
        area: '',
        zipcode: '',
        addr: '',
        email: '',
        receipt_title: '',
        fractions: [],
        pay_id: '',
        food_type: '',
      },
      inputFormat: {
        nameInput: {
          label: '姓名',
          type: 'text',
          placeholder: '請輸入姓名',
          required: true,
        },
        idInput: {
          label: '身分證字號',
          type: 'text',
          placeholder: '請輸入身分證字號',
          required: true,
        },
        phoneInput: {
          label: '手機號碼',
          type: 'text',
          placeholder: '請輸入手機號碼',
          required: true,
        },
        emailInput: {
          label: 'Email',
          type: 'email',
          placeholder: '請輸入完整Email',
          required: true,
        },
        hospitalIdInput: {
          label: '院所名稱(例如：台灣居家醫療醫學會)',
          type: 'text',
          placeholder: '請輸入完整名稱',
          required: true,
        },
        addressHospitalDetailInput: {
          label: '',
          type: 'text',
          placeholder: '請填寫院所地址',
          required: true,
        },
        feeTitleInput: {
          label: '收據抬頭',
          type: 'text',
          placeholder: '請輸入抬頭',
          required: false,
        },
        levelRadio: {
          label: '身份',
          name: 'level',
          required: true,
          options: [
            {
              name: '醫師',
              value: '',
            },
            // {
            //   name: '護理人員',
            //   value: '4',
            // },
          ],
        },
        foodRadio: {
          label: '飲食習慣',
          name: 'food_type',
          required: true,
          options: [
            {
              name: '葷食',
              value: '1',
            },
            {
              name: '素食',
              value: '0',
            },
          ],
        },
      },
      /**
       * Axios Response Data
       */
      eventHeaderItems: {},
      eventInfo: {},
    };
  },
  created() {
    /** 1. 判斷是否已選取課程 */
    if (this.$route.query && this.$route.query.event_id) {
      this.eventID = this.$route.query.event_id;
      this.user.event_uuid = this.eventID;

      /** 取得活動資料 */
      eventsView({ uuid: this.eventID }).then((result) => {
        const target = result.result.data;
        this.eventInfo = target;
        this.eventHeaderItems.name = target.name;
        this.eventHeaderItems.eventNo = target.no_number;
        this.eventHeaderItems.fraction_a = target.fraction_a;
        this.eventHeaderItems.fraction_b = target.fraction_b;

        /** Refresh EventHeader */
        this.componentKey += 1;

        /** 2. 判斷是否超過報名期間 */
        if (todayValid(target.apply_start_time, target.apply_end_time)) {
          /** 3. 判斷是否超過名額 */
          if (quotaValid(target.apply_amount, target.apply_limit)) {
            /** 4. 判斷是否符合報名身份 */
            const userLevel = this.$cookies.get('level');
            if (levelValid(userLevel, target.price)) {
              /** 5. 判斷是否已購買 */
              if (target.bought_completed !== true) {
                /** 6. 判斷是否為修改報名資料：cookie是否存在 */
                const filledUser = this.$cookies.get('eventApplyInfo');
                if (filledUser) {
                  /** 7. 判斷cookie內的資料是否與選取的課程uuid相同，是則帶入資料 */
                  if (filledUser.event_uuid === this.eventID) {
                    this.user = filledUser;
                  /** 課程uuid不同則帶入註冊資料 */
                  } else {
                    this.getUser();
                  }
                /** cookie不存在則預設帶入註冊資料 */
                } else {
                  this.getUser();
                }
              } else {
                this.openModal({
                  url: '/register/record',
                  message: '已購買過此課程，請至「活動紀錄」確認',
                });
              }
            } else {
              this.openModal({
                url: '/register/event',
                message: '報名身份不符，請返回總覽頁重新選擇',
              });
            }
          } else {
            this.openModal({
              url: '/register/event',
              message: '報名人數已滿，請返回總覽頁重新選擇',
            });
          }
        } else {
          this.openModal({
            url: '/register/event',
            message: '已超過報名期限，請返回總覽頁重新選擇',
          });
        }

        /** 判斷是否曾經購買 */
        // if (result.result.data.bought_completed !== true) {

        //   if (this.hasToken) {
        //     /** 修改報名表：若cookies內有資料則判斷是否帶入已填好的資料 */
        //     const filledUser = this.$cookies.get('eventApplyInfo');
        //     if (filledUser) {
        //       /** 若cookies內的活動uuid與所選的課程相同，才帶入資料 */
        //       if (filledUser.event_uuid === this.eventID) {
        //         this.user = filledUser;

        //       /** 否則也是帶入註冊資訊 */
        //       } else {
        //         userInfo().then((res) => {
        //           this.user.realname = res.realname || '';
        //           this.user.level = res.level || '';
        //           this.user.id_number = res.id_number || '';
        //           this.user.phone = res.phone;
        //           this.user.hospital_name = res.jobs.hospital_name || '';
        //           this.user.city = res.jobs.hospitalCity || '';
        //           this.user.area = res.jobs.hospitalArea || '';
        //           this.user.zipcode = res.jobs.hospitalZipcode || '';
        //           this.user.addr = res.jobs.hospitalAddr || '';
        //           this.user.email = res.email || '';
        //           this.user.receipt_title = res.realname || '';

        //           // this.user_no = res.no || '';
        //           // this.user.birthday = res.birthday || '';
        //         });
        //       }

        //     /** 否則帶入註冊資訊 */
        //     } else {
        //       userInfo().then((res) => {
        //         this.user.realname = res.realname || '';
        //         this.user.level = res.level || '';
        //         this.user.id_number = res.id_number || '';
        //         this.user.phone = res.phone;
        //         this.user.hospital_name = res.jobs.hospital_name || '';
        //         this.user.city = res.jobs.hospitalCity || '';
        //         this.user.area = res.jobs.hospitalArea || '';
        //         this.user.zipcode = res.jobs.hospitalZipcode || '';
        //         this.user.addr = res.jobs.hospitalAddr || '';
        //         this.user.email = res.email || '';
        //         this.user.receipt_title = res.realname || '';

        //         // this.user_no = res.no || '';
        //         // this.user.birthday = res.birthday || '';
        //       });
        //     }
        //   }
        // } else {
        //   this.openModal({
        //     url: '/register/record',
        //     message: '已購買過此課程，請至「活動紀錄」確認',
        //   });
        // }
      });
    } else {
      this.openModal({
        url: '/register/event',
        message: '尚未選取任何學術活動，請返回總覽頁選擇',
      });
    }
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'hasToken',
      'eatType',
    ]),
    eatTypeRadio() {
      const optionsCopy = this.eatType.map((item) => ({
        ...item,
        value: item.id,
      }));

      return { ...this.inputFormat.foodRadio, options: optionsCopy };
    },
    pageTitle() {
      if (this.isAuthenticated) {
        return '會員報名';
      }
      return '非會員報名';
    },
    urlQuery() {
      return {
        name: 'event_id',
        value: this.eventID,
      };
    },
    hospitalTowns() {
      return cities.map((city) => city.name);
    },
    hospitalAreas() {
      const hospitalTownIndex = this.hospitalTowns.indexOf(this.user.city);
      if (hospitalTownIndex >= 0) {
        return cities[hospitalTownIndex].areas.map((area) => area.name);
      }
      return [];
    },
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),

    /**
     * 取得登入者資料
     */
    getUser() {
      userInfo().then((res) => {
        this.user.realname = res.realname || '';
        this.user.level = res.level || '';
        this.user.id_number = res.id_number || '';
        this.user.phone = res.phone;
        this.user.hospital_name = res.jobs.hospital_name || '';
        this.user.city = res.jobs.hospitalCity || '';
        this.user.area = res.jobs.hospitalArea || '';
        this.user.zipcode = res.jobs.hospitalZipcode || '';
        this.user.addr = res.jobs.hospitalAddr || '';
        this.user.email = res.email || '';
        this.user.receipt_title = res.realname || '';

        // this.user_no = res.no || '';
        // this.user.birthday = res.birthday || '';
      });
    },

    /**
     * 地址連動選單
     */
    updateHospitalZip() {
      const hospitalTownIndex = this.hospitalTowns.indexOf(this.user.city);
      const hospitalAreaIndex = this.hospitalAreas.indexOf(this.user.area);
      if (hospitalAreaIndex >= 0) {
        this.user.zipcode = cities[hospitalTownIndex].areas[hospitalAreaIndex].zip;
      }
      if (hospitalAreaIndex === -1) {
        this.user.zipcode = '';
      }
      this.addressHospitalTest();
    },
    resetHospitalSelect() {
      this.user.area = '';
      this.user.zipcode = '';
      this.addressHospitalTest();
    },
    addressHospitalTest() {
      if (!this.user.city || !this.user.area) {
        this.hospitalAddressErrorMsg = '必選欄位！請選擇縣市及區域！';
      }
      if (this.user.city && this.user.area) {
        this.hospitalAddressErrorMsg = '';
      }
    },

    /**
     * 積分選擇
     */
    fractionRadio(data) {
      const options = [];
      if (data.fraction_a !== 0) {
        options.push({
          name: 'Ａ類',
          num: data.fraction_a,
          value: 'fraction_a',
        });
      }
      if (data.fraction_b !== 0) {
        options.push({
          name: 'B類',
          num: data.fraction_b,
          value: 'fraction_b',
        });
      }
      return {
        uuid: data.uuid,
        label: '請選擇ＡＢ類積分',
        name: 'fractionRadio',
        options,
      };
    },
    isFractionExist(event) {
      const checkArray = [];
      if (event && event.fractions) {
        Object.keys(event.fractions).forEach((key) => {
          if (event.fractions[key] * 1 !== 0) {
            checkArray.push(true);
          }
        });
      }
      if (checkArray.length > 0) {
        return true;
      }
      return false;
    },
    fractionCheckbox(data) {
      return data;
    },
    updateFraction(data) {
      // console.log('updated', data.fraction);
      const indexA = this.user.fractions.indexOf('fraction_a');
      const indexB = this.user.fractions.indexOf('fraction_b');
      /** checkbox 勾選結果 */
      if (Array.isArray(data.fraction)) {
        if (indexA >= 0) {
          this.user.fractions = [...data.fraction, 'fraction_a'];
        }
        if (indexB >= 0) {
          this.user.fractions = [...data.fraction, 'fraction_b'];
        }
        if (indexA === -1 && indexB === -1) {
          this.user.fractions = [...data.fraction];
        }
      /** radio 勾選結果 */
      } else {
        if (indexA >= 0) {
          this.user.fractions.splice(indexA, 1);
        }
        if (indexB >= 0) {
          this.user.fractions.splice(indexB, 1);
        }
        this.user.fractions.push(data.fraction);
      }
    },
    chosenRadioFilter(fractions) {
      let target = null;
      if (fractions) {
        if (fractions.indexOf('fraction_a') !== -1) {
          target = 'fraction_a';
        }
        if (fractions.indexOf('fraction_b') !== -1) {
          target = 'fraction_b';
        }
      }
      return target;
    },
    chosenCheckFilter(fractions) {
      const target = [...fractions];
      // const indexA = target.indexOf('fraction_a');
      // const indexB = target.indexOf('fraction_b');
      // if (indexA) {
      //   target.splice(indexA, 1);
      // }
      // if (indexB) {
      //   target.splice(indexB, 1);
      // }
      return target;
    },

    /**
     * 表單送出
     */
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      this.addressHospitalTest();
      if (this.hospitalAddressErrorMsg !== '') {
        errorList.push(true);
      }
      if (errorList.indexOf(true) === -1) {
        this.$cookies.set('eventApplyInfo', JSON.stringify(this.user));
        this.$router.push({ path: `/register/event/payment?event_id=${this.eventID}` });
      } else {
        // console.log('必填欄位尚未完成！');
      }
    },
  },
  components: {
    EventHeader,
    PageSubHeader,
    TextInput,
    Radio,
    RadioFraction,
    CheckboxFraction,
    DatePick,
  },
};
</script>
