<template>
  <div class="title-wrap page_header_space">
    <img class="heart-bg" src="@/statics/img/index/deco_heart_r.png" alt="">
    <div class="w1100">
      <div class="top">
        <h3 class="txt-bold">{{ eventHeaderItems.name }}</h3>
      </div>
      <div class="bottom">
        <p class="type-A small" v-if="hasFractionA">
          <span class="tag">A類</span>
          <span class="credit">．{{ eventHeaderItems.fraction_a }}學分</span>
        </p>
        <p class="type-B small" v-if="hasFractionB">
          <span class="tag">B類</span>
          <span class="credit">．{{ eventHeaderItems.fraction_b }}學分</span>
        </p>
        <p class="event_id small" v-if="hasEventNo">
          活動編號：{{ eventHeaderItems.eventNo }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventHeader',
  props: [
    'eventHeaderItems',
  ],
  data() {
    return {

    };
  },
  computed: {
    hasFractionA() {
      if (this.eventHeaderItems.fraction_a
        && parseInt(this.eventHeaderItems.fraction_a, 10) > 0) {
        return true;
      }
      return false;
    },
    hasFractionB() {
      if (this.eventHeaderItems.fraction_b
        && parseInt(this.eventHeaderItems.fraction_b, 10) > 0) {
        return true;
      }
      return false;
    },
    hasEventNo() {
      if (this.eventHeaderItems.eventNo) {
        return true;
      }
      return false;
    },
  },
};
</script>
